import _get from 'lodash/get';
import _find from 'lodash/find';
import _clone from 'lodash/clone';
import {
    CLEAR_REDUCER,
    GET_BDAA_SUGGESTION_SUCCESS,
    GET_PRODUCT_LIST_SUCCESS,
    RESET_PRODUCT_LIST,
    SET_BDAA_ISACTIVE,
    SET_PLP_VIEW_CHOICE_SUCCESS,
    TOGGLE_PRODUCT_VIEW,
    UPDATE_FAVOURITE_IN_CART,
    UPDATE_FAVOURITE_IN_PRODUCT_LIST,
    UPDATE_PRODUCT_SORT
} from '../actionTypes/ProductListActionTypes';
import { UPDATE_BDAA_PRODUCT_FAVOURITE_PROP } from '../actionTypes/FavouriteActionTypes';
import { PLP_BANNER_CONTENT_SUCCESS } from '../actionTypes/PageContentActionTypes';
import { SET_SPINNER } from '../actionTypes/LoaderActionTypes';
import { PLP_L10N, PLP_SORTS_VALUES, UNIQUE_ID } from '../common/Constants';
import { filterProductListingData } from '../service/ProductListFilter';
import { SessionStorage } from '../common/Storage/Storage';
import { SUBSCRIBE_TO_PRODUCT_SUCCESS } from '../actionTypes/NotifyMeActionTypes';

const initialState = {
    plpBannerData: {},
    products: undefined,
    isReset: false,
    orderBy: SessionStorage.getData(UPDATE_PRODUCT_SORT) || PLP_SORTS_VALUES.DEFAULT,
    customerHasSavedFilter: false,
    isSavedFilterDefault: false,
    savedFilters: '',
    bdaaSuggestion: [],
};

export function getNewProducts(state, transformedData) {
    const products = new Map();
    const allProducts = [...(state.products ? state.products : []), ...transformedData.products];

    allProducts.forEach(product => {
        products.set(product.sku.id, product);
    });

    return Array.from(products.values());
}

export default function ProductListReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PRODUCT_LIST_SUCCESS: {
            const transformedData = filterProductListingData(action.data);
            const products = getNewProducts(state, transformedData);
            // Preserving query for the product list result to decided to whether to make API call or not in PageContentSaga.
            return {
                ...state,
                ...transformedData,
                products,
                queryResult: action.data.queryResult,
                isReset: false,
                blockAddToCartEnabled: action?.data?.blockAddToCartEnabled
            };
        }
        case SET_SPINNER: {
            return {
                ...state,
                spinner: action.data
            };
        }
        case TOGGLE_PRODUCT_VIEW:
            return {
                ...state,
                isListViewEnabled: !state.isListViewEnabled
            };

        case UPDATE_FAVOURITE_IN_PRODUCT_LIST: {
            const newData = { ...state };

            const { products } = newData;
            const index = products.findIndex(item => item.code === action.code);

            products[index] = {
                ...products[index],
                isFav: action.isFavourite
            };
            return {
                ...state,
                products: newData.products
            };
        }
        case PLP_BANNER_CONTENT_SUCCESS: {
            const data = _get(action, 'data.components');
            const plpBannerData = _find(data, [UNIQUE_ID, PLP_L10N.PLP_BANNER_IMAGE_UID]) || {};

            return {
                ...state,
                plpBannerData
            };
        }

        case RESET_PRODUCT_LIST: {
            return {
                ...initialState,
                orderBy: action?.payload?.sortType || PLP_SORTS_VALUES.DEFAULT,
                bdaaSuggestion: state.bdaaSuggestion,
                isBdaaActive: state.isBdaaActive,
                isReset: !action.data // To identify for making Search API API call in the Component for RDD.
            };
        }

        case CLEAR_REDUCER: {
            return {
                ...initialState
            };
        }

        case GET_BDAA_SUGGESTION_SUCCESS: {
            const newState = { ...state };
            const transformedData = filterProductListingData(action.data);
            newState.bdaaSuggestion = transformedData.products;
            return newState;
        }

        case UPDATE_FAVOURITE_IN_CART: {
            const bdaaSuggestion = _clone(state.bdaaSuggestion);

            const index = bdaaSuggestion.findIndex(item => item.code === action.code);
            bdaaSuggestion[index].isFav = action.isFavourite;

            return {
                ...state,
                bdaaSuggestion
            };
        }

        case UPDATE_BDAA_PRODUCT_FAVOURITE_PROP: {
            const newbdaaSuggestion = [...state.bdaaSuggestion];
            const productIndex = state.bdaaSuggestion.findIndex(product => {
                return product.code === action.payload.code;
            });

            if (productIndex !== -1) {
                newbdaaSuggestion[productIndex].isFav = !action.payload.isFav;
            }

            return {
                ...state,
                bdaaSuggestion: newbdaaSuggestion
            };
        }

        case SET_BDAA_ISACTIVE: {
            return {
                ...state,
                isBdaaActive: action.payload
            };
        }

        case UPDATE_PRODUCT_SORT: {
            return {
                ...state,
                orderBy: action.payload
            };
        }
        case SUBSCRIBE_TO_PRODUCT_SUCCESS: {
            const newData = state;
            const { products } = newData;

            if (!products) {
                return state;
            }
            const index = products.findIndex(item => item.code === action.params.code);

            products[index] = {
                ...products[index],
                stockNotified: true
            };

            return {
                ...state,
                products
            };
        }
        case SET_PLP_VIEW_CHOICE_SUCCESS: {
            const viewChoice = action.payload;
            const { viewchoice } = viewChoice;
            return {
                ...state,
                viewChoice: viewchoice
            };
        }
        default:
            return state;
    }
}
