/* eslint-disable import/prefer-default-export */
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { COMMON_COMPONENT, LOGIN_INFO, MOBILE_NAV } from '../common/Constants';
import { getLocalization } from '../common/UserManager/UserManager';
const removeMenuNavBarLinks = ['MyEquipmentLink', 'MyRewardsLink'];

const extractLinksOld = components => {
    const menuList = [];

    (components || []).forEach(component => {
        const menus = {
            linkName: component.name,
            linkUrl: component.url,
            uid: component.uid
        };

        menus.children = [];
        (component.childComponents || []).forEach(subMenu => {
            const menuObj = {
                uid: subMenu.uid
            };

            if (_isEmpty(subMenu.childComponents)) {
                menuObj.linkName = subMenu.name;
                menuObj.linkUrl = subMenu.url;
            } else {
                (subMenu?.childComponents || []).forEach(subMenuComponent => {
                    const image = _find(subMenuComponent.childComponents, ['type', COMMON_COMPONENT.SIMPLE_BANNER]);
                    if (image) {
                        menuObj.imageUrl = image.url;
                        menuObj.altText = image.altText;
                    }
                    const menuName = _find(subMenuComponent.childComponents, ['type', COMMON_COMPONENT.SUNMENU_NAME]);
                    if (menuName) {
                        menuObj.linkName = menuName.name;
                        menuObj.linkUrl = menuName.url;
                    }
                });
            }
            if (!_isEmpty(menuObj)) {
                menus.children.push(menuObj);
            }
        });

        if (_isEmpty(menus.children)) {
            delete menus.children;
        }
        menuList.push(menus);
    });
    return menuList;
};

function extractLinks(data) {
    const links = [];
    const list = Array.isArray(data) ? data : data?.childComponents || [];
    for (const component of list) {
        if (component.type === COMMON_COMPONENT.SUNMENU_NAME) {
            const link = { linkName: component.name, linkUrl: component.url, uid: component.uid };
            const bannerComponent = (component?.childComponents || []).find(
                cc => cc?.type === COMMON_COMPONENT.SIMPLE_BANNER
            );
            const findCMSLinkComponent = (component?.childComponents || []).find(
                cc => cc?.type === COMMON_COMPONENT.SUNMENU_NAME
            );
            if (bannerComponent) {
                link.imageUrl = bannerComponent.url;
                link.altText = '';
            }
            if (findCMSLinkComponent) {
                link.uid = findCMSLinkComponent.uid;
            }

            links.push(link);
        } else if (component.childComponents) {
            const link = { linkName: component.name, uid: component.uid };
            if (
                component.childComponents &&
                (component.childComponents || []).some(cc => cc?.type === COMMON_COMPONENT.SUNMENU_NAME)
            ) {
                link.children = extractLinks(component);
            }
            links.push(link);
        }
    }
    return links;
}

const buildNavigationComponents = components => {
    try {
        return extractLinks(components);
    } catch (error) {
        console.error(error);
    }
};

const buildMobileNavigationComponents = (components = {}, accountComponents, oldHeaderData) => {
    const links = extractLinks(components, true);

    let mobileMenuList = [];

    (links || [])
        .filter(link => link.uid !== 'MyEquipmentMenuLink')
        .forEach(component => {
            const menus = component;
            if (component.uid === MOBILE_NAV.MY_ACCOUNT_MENU_LINK) {
                menus.children = [
                    ...(accountComponents?.childComponents || []).map(({ linkUrl, ...comp }) => {
                        const newComp = {
                            ...comp,
                            link: linkUrl
                        };
                        return newComp;
                    })
                ];

                (oldHeaderData || []).forEach(menuItem => {
                    if (menuItem.children) {
                        menus.children = [
                            ...(menus.children || []),
                            {
                                children: menuItem.children,
                                name: menuItem.linkName
                            }
                        ];
                    }
                });
            }

            mobileMenuList = [...mobileMenuList, menus];
        });

    const newMobileMenuListArr = [...mobileMenuList];
    if (newMobileMenuListArr?.length > 0) {
        const newIndex = newMobileMenuListArr[1];
        newMobileMenuListArr[1] = newMobileMenuListArr[2];
        newMobileMenuListArr[2] = newIndex;
    }

    return newMobileMenuListArr;
};

export const filterCommonData = action => {
    const headerData = {};
    const footerData = {};
    const mobileMenuData = {};
    let ordersAndReturnsMenu = {};
    const country = getLocalization()[LOGIN_INFO.COUNTRY] || '';
    const { data = {} } = action || {};
    const {
        showChatWidget,
        enableMyReports,
        currentDiscountFrom,
        currentDiscountTo,
        nextDiscountFrom,
        nextDiscountTo,
        selfREDDiscountForCurrentPeriod,
        selfREDDiscountForNextPeriod,
        showSelfREDSection,
        showOnboardingSelfREDPopUp,
        onboardingTermsAndConditionsURL,
        chatEmail,
        chatCountry,
        chatLanguage,
        chatCurrentOutletId,
        chatCurrentCompanyName,
        readNowURL
    } = data;

    (data.components || []).forEach(component => {
        if (component.uid === COMMON_COMPONENT.HEADER) {
            headerData.menuList = buildNavigationComponents(component.childComponents);

            let accountComponents = data.components.find(({ uid }) => uid === COMMON_COMPONENT.ACCOUNT_MENU);

            let oldHeaderData = data.components.find(({ uid }) => uid === COMMON_COMPONENT.HEADER_OLD);
            if (oldHeaderData) {
                oldHeaderData.menuList = extractLinksOld(oldHeaderData.childComponents);
            }

            accountComponents = {
                ...accountComponents,
                childComponents: [...(accountComponents?.childComponents || [])].filter(
                    cc => !removeMenuNavBarLinks.includes(cc.uid)
                )
            };
            const mobileTabBarComponents = data.components.find(
                ({ uid }) => uid === COMMON_COMPONENT.MOBILE_NAVIGATION
            );
            if (mobileTabBarComponents && accountComponents && oldHeaderData?.menuList) {
                mobileMenuData.menuList = buildMobileNavigationComponents(
                    mobileTabBarComponents,
                    accountComponents,
                    oldHeaderData.menuList
                );
            } else {
                mobileMenuData.menuList = [];
            }
        } else if (component.uid === COMMON_COMPONENT.HEADER_OLD) {
            const res = extractLinksOld(component.childComponents);
            const findOrdersReturns = res.find(comp => comp.uid === COMMON_COMPONENT.HEADER_ORDERS_OLD);
            if (findOrdersReturns) {
                ordersAndReturnsMenu = findOrdersReturns;
            }
        } else if (component.uid === COMMON_COMPONENT.ACCOUNT_MENU) {
            // This is temporary until we have the two pages done
            headerData[component.uid] = {
                ...component,
                childComponents: [...(component?.childComponents || [])].filter(
                    cc => !removeMenuNavBarLinks.includes(cc.uid)
                )
            };
        } else if (component.uid === COMMON_COMPONENT.LOGO_COMPONENT) {
            headerData.logo = {
                imageUrl: component.url,
                link: component.urlLink
            };
        } else if (component.uid === COMMON_COMPONENT.MOBILE_LOGO_COMPONENT) {
            headerData.mobilelogo = {
                imageUrl: component.url,
                link: component.urlLink,
                alt: component.altText
            };
        } else if (component.uid === COMMON_COMPONENT.MOBILE_LOGO_CUT_COMPONENT) {
            headerData.mobilelogoCut = {
                imageUrl: component.url,
                link: component.urlLink
            };
        } else if (component.uid === COMMON_COMPONENT.FOOTER_COMPONENT_UID) {
            (component.childComponents || []).forEach(footChildComp => {
                if (footChildComp.uid === COMMON_COMPONENT.FOOTER_MENU) {
                    footerData.menuList = [
                        ...(footerData?.menuList || []),
                        ...buildNavigationComponents(footChildComp)
                    ];
                }
                if (footChildComp.uid === COMMON_COMPONENT.FOOTER_MY_ACCOUNT_LINKS) {
                    const navComponents = buildNavigationComponents(footChildComp);
                    const findMyAccount = navComponents.find(cc => cc.uid === COMMON_COMPONENT.FOOTER_MY_ACCOUNT_LINK);
                    const myAccountStructured = {
                        ...findMyAccount,
                        children: navComponents
                    };
                    footerData.menuList = [...(footerData?.menuList || []), myAccountStructured];
                }
                if (footChildComp.uid === COMMON_COMPONENT.FOOTER_MY_SERVICES_LINKS) {
                    const navComponents = buildNavigationComponents(footChildComp);
                    const findMyServices = navComponents.find(
                        cc => cc.uid === COMMON_COMPONENT.FOOTER_MY_SERVICES_SUPPORT_LINK
                    );
                    const myAccountStructured = {
                        ...findMyServices,
                        children: navComponents
                    };
                    footerData.menuList = [...(footerData?.menuList || []), myAccountStructured];
                }
                if (footChildComp.uid === COMMON_COMPONENT.FOOTER_LOGO_COMPONENT) {
                    const footerLogo = footChildComp?.childComponents?.[0] || {};
                    footerData.logo = {
                        imageUrl: footerLogo.url || '',
                        link: footerLogo.linkUrl || '',
                        altText: footerLogo.altText || ''
                    };
                }
                if (footChildComp.uid === COMMON_COMPONENT.FOOTER_ICON_COMPONENT) {
                    footerData.iconList = buildNavigationComponents(footChildComp);
                }
                if (footChildComp.uid === COMMON_COMPONENT.FOOTER_BOTTOM_LINKS) {
                    footerData.bottomMenuList = [];

                    (footChildComp?.childComponents || []).forEach(bottomMenuComp => {
                        if (_isEmpty(bottomMenuComp.childComponents)) {
                            footerData.bottomMenuList.push(bottomMenuComp);
                        } else {
                            const bottomMenuChild = bottomMenuComp?.childComponents?.[0] || {};
                            footerData.bottomMenuList.push({
                                name: bottomMenuComp.name || '',
                                url: bottomMenuChild.url || '',
                                uid: bottomMenuChild.uid || ''
                            });
                        }
                    });
                }
            });
            footerData.copyRightText = component.notice;
        } else {
            headerData[component.uid] = component;
        }
    });
    if (ordersAndReturnsMenu && headerData?.menuList) {
        const findPromoIndex = (headerData?.menuList || []).findIndex(
            menu => menu.uid === COMMON_COMPONENT.HEADER_NAVIGATION_LINK_PROMOTION
        );
        if (findPromoIndex !== -1) {
            headerData.menuList.splice(findPromoIndex + 1, 0, ordersAndReturnsMenu);
        } else {
            headerData.menuList = [...(headerData?.menuList || []), ordersAndReturnsMenu];
        }
    }

    return {
        pageTitle: `${data.title || ''} ${country}`,
        ...((Object.keys(mobileMenuData).length > 0 && { mobileMenuData }) || {}),
        headerData,
        footerData,
        showChatWidget,
        enableMyReports,
        showOnboardingSelfREDPopUp,
        onboardingTermsAndConditionsURL,
        chatEmail,
        chatCountry,
        chatLanguage,
        chatCurrentOutletId,
        chatCurrentCompanyName,
        currentDiscountFrom,
        currentDiscountTo,
        selfREDDiscountForCurrentPeriod,
        selfREDDiscountForNextPeriod,
        nextDiscountFrom,
        nextDiscountTo,
        showSelfREDSection,
        readNowURL,
        components: data.components,
        breadcrumbs: _get(data, 'breadcrumbs', []),
        localization: { ...data.localization, pageId: action.pageId } // 'pageId' to indentify which page localization content.
    };
};
