import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import Layout from '../../../components/site/Layout/Layout';
import Icon from '../../../components/core/Icon/Icon';
import AddToOrderTemplate from '../../../components/site/AddToOrderTemplate/AddToOrderTemplate';
import AddToCart from '../../../components/site/AddToCart/AddToCart';
import SelectBox from '../../../components/core/SelectBox/SelectBox';
import Accordion from '../../../components/core/Accordion/Accordion';
import Image from '../../../components/core/Image/Image';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import {
    getCurrency,
    isUseMarketingMaterials,
    isBdaaActive,
    mainPartnerId,
    mainPartnerCode,
    getBdaaRecommendedCheckboxInfo,
    setBdaaRecommendedCheckboxInfo,
    clearBdaaRecommendedCheckboxInfo,
    getEnabledEURConversion
} from '../../../common/UserManager/UserManager';
import {
    PAGE_ID,
    PDP_L10N,
    TEMP_POPUP_L10N,
    IMAGE_COMING_SOON,
    GENERIC_L10N,
    URL_PATHS,
    PLP_L10N
} from '../../../common/Constants';
import { getPageContent, setPageTitlePrefix } from '../../../actions/PageContentAction';
import { getProductDetails, resetProductDetails } from '../../../actions/ProductDetailAction';
import { addToFavouriteEvent, removeFavouriteEvent } from '../../../actions/FavouriteAction';
import { getBdaaSuggestion } from '../../../actions/ProductListAction';
import * as styles from './ProductDetail.css';
import { viewItemEventGA4 } from '../../../common/GoogleTagManager/GoogleTagManager';
import StockNotificationModal from '../../../components/core/StockNotificationModal/StockNotificationModal';
import { getProductPrice } from '../../../service/DataLayerFilter';
import { openSubscriptionPopup } from '../../../actions/NotifyMeAction';
import NotificationSubscription from '../../../components/site/NotificationSubscription/NotificationSubscription';
import Checkbox from '../../../components/core/Checkbox/Checkbox';
import { convertBGNToEUR } from '../../../common/Utils/Utils';

const cx = classNames.bind(styles);

const productShowcaseFeature = {
    inStock: Symbol('IN_STOCK'),
    outOfStock: Symbol('OUT_OF_STOCK'),
    promotional: Symbol('PROMOTIONAL')
};

export class ProductDetail extends Component {
    static propTypes = {
        productDetailData: PropTypes.objectOf(PropTypes.any),
        cartDeliveryDate: PropTypes.string,
        localization: PropTypes.objectOf(PropTypes.string),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        productDetailData: {},
        cartDeliveryDate: '',
        localization: {},
        commonLocalization: {}
    };

    constructor(props) {
        super(props);
        this.isBdaaActive = isBdaaActive();
        this.mainPartnerId = mainPartnerId();
        this.mainPartnerCode = mainPartnerCode();
        this.bdaaRecommendedCheckboxInfo = getBdaaRecommendedCheckboxInfo();
        this.enabledEURConversion = getEnabledEURConversion();
        this.state = {
            units: '',
            imgActive: 0,
            addClass: false,
            showNotifyPopup: false,
            bdaaRecommendedCheckboxInfo: this.bdaaRecommendedCheckboxInfo
        };

        if (this.isBdaaActive) {
            this.props.actions.getBdaaSuggestion({
                data: { countryCode: this.mainPartnerCode, customerId: this.mainPartnerId }
            });
        }
    }

    componentDidMount() {
        const {
            actions,
            params: { productId }
        } = this.props;
        actions.getPageContent({ pageId: PAGE_ID.PDP, pdpId: productId });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            params,
            productDetailData: { name = '' },
            cartDeliveryDate
        } = this.props;

        if (
            prevProps.productDetailData.code !== this.props.productDetailData.code &&
            Object.keys(this.props.productDetailData).length > 0
        ) {
            try {
                const productDetailData = _cloneDeep(this.props.productDetailData);
                const foundPrice = (productDetailData?.itemDetails || [])
                    .slice()
                    .reverse()
                    .find(({ value }) => /^(0(?!\.00)|[1-9]\d{0,6})\.\d{2}$/.test(value));
                viewItemEventGA4({
                    item_id: productDetailData?.dataLayer.id,
                    item_name: productDetailData?.dataLayer.name,
                    item_brand: productDetailData?.dataLayer.brand,
                    price: foundPrice ? getProductPrice(foundPrice.value) : 0,
                    quantity: 1,
                    currency: getCurrency().isocode
                });
            } catch (ex) {
                console.error(ex);
            }
        }

        // Update the PDP when the cart delivery date is changed or in coming product id is different.
        if (prevProps.params.productId !== params.productId || prevProps.cartDeliveryDate !== cartDeliveryDate) {
            prevProps.actions.resetProductDetails();
            prevProps.actions.getProductDetails({ pdpId: params.productId });
            this.resetImgActive();
        } else if (name) {
            this.props.actions.setPageTitlePrefix(name.toUpperCase());
        }

        // Fetch the current data from localStorage
        const localStorageData = getBdaaRecommendedCheckboxInfo();
        // Check if the state is different from localStorage
        if (JSON.stringify(prevState.bdaaRecommendedCheckboxInfo) !== JSON.stringify(localStorageData)) {
            // If different, update the state to match localStorage
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                bdaaRecommendedCheckboxInfo: localStorageData
            });
        }

        // Check if minicart items goes to zero. If so, clear the local storage of bdaaRecommendedCheckboxInfo
        // Check if minicart items goes to zero. If so, set checked to false inside the local storage object
        if (!_isEqual(prevProps.miniCartItems.length, this.props.miniCartItems.length)) {
            if (this.props.miniCartItems.length === 0) {
                clearBdaaRecommendedCheckboxInfo();
            } else {
                const productCodes = Object.keys(localStorageData).map(key => key);
                const newProductCodes = this.props.miniCartItems.map(item => item.code);
                // Loop through each product code in productCodes
                // Create an object to hold only the updated data (product codes with checked: false)
                const updatedBdaaInfo = {};

                productCodes.forEach(code => {
                    if (!newProductCodes.includes(code)) {
                        // Check if the product exists in localStorageData before modifying it
                        if (localStorageData[code]) {
                            // Set the checked property to false if the product exists in localStorageData
                            updatedBdaaInfo[code] = { checked: false }; // Only include the `checked` property in the update
                        }
                    }
                });
                if (Object.keys(updatedBdaaInfo).length > 0) {
                    setBdaaRecommendedCheckboxInfo(updatedBdaaInfo);
                    // eslint-disable-next-line react/no-did-update-set-state
                    this.setState({
                        bdaaRecommendedCheckboxInfo: {
                            ...localStorageData,
                            ...updatedBdaaInfo
                        }
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        const { actions } = this.props;
        actions.resetProductDetails();
        actions.setPageTitlePrefix();
    }

    /**
     * Determines which display feature to use for product label
     * @returns {symbol}
     */
    getShowcasedFeature() {
        const { productDetailData } = this.props;

        const isOutOfStock = productDetailData?.stockFlag === 'OUT_OF_STOCK';
        const isInStock = productDetailData?.stockFlag === 'IN_STOCK';
        const isPromotional = productDetailData?.isPromoProduct === true;

        let renderOption = null;
        if (isInStock) renderOption = productShowcaseFeature.inStock;
        if (isPromotional) renderOption = productShowcaseFeature.promotional;
        if (isOutOfStock) renderOption = productShowcaseFeature.outOfStock;
        return renderOption;
    }

    resetImgActive = () => {
        this.setState({ imgActive: 0 });
    };

    onUnitsChangeHandler = event => {
        this.setState({ units: event });
    };

    handleFavourite = () => {
        const {
            actions,
            productDetailData,
            params: { productId }
        } = this.props;
        const payload = {
            code: productId,
            from: 'PDP'
        };
        if (productDetailData.isFavourite) {
            actions.removeFavouriteEvent(payload);
        } else {
            actions.addToFavouriteEvent(payload);
        }
    };

    handleThumbnails = imgId => {
        this.setState({ imgActive: imgId });
    };

    pdpPromoToggle = isGroup => {
        if (isGroup) {
            const { addClass } = this.state;
            this.setState({ addClass: !addClass });
        }
    };

    renderNotificationModal = () => {
        return (
            <StockNotificationModal
                localization={this.props.localization}
                showNotifyPopup={this.state.showNotifyPopup}
                subscribeCustomerToTheProduct={this.subscribeCustomerToTheProduct}
                onCloseModal={() => this.onCloseModal()}
            />
        );
    };

    openStockNotificationModal = () => {
        this.setState({
            showNotifyPopup: true
        });
    };

    onCloseModal = () => {
        this.setState({
            showNotifyPopup: false
        });
    };

    subscribeCustomerToTheProduct = email => {
        this.props.actions.subscribeToProduct({
            params: { email, code: this.props.params.productId, isPLP: false }
        });
        this.setState({
            showNotifyPopup: false
        });
    };

    renderPromotions = () => {
        const { productDetailData, localization, commonLocalization } = this.props;
        const { promotions } = productDetailData;
        const limitedQuantity = promotions &&
            promotions.listOfLimitedProductCodes.length > 0 &&
            promotions.listOfLimitedProductCodes.includes(productDetailData.SKU) && (
                <div className={cx('limitedQuantityContainer')}>
                    <Icon iconType='svg' width='16px' height='16px' viewBox='0 0 22 21' name='info-red' />
                    <p className={cx('limitedQuantityLabel')}>{commonLocalization[GENERIC_L10N.LIMITED_QUANTITY]}</p>
                </div>
            );

        return (
            <div className={cx('pdppromo-Panel')} automation-id='at-promotions-details'>
                <div className={cx(`${this.state.addClass ? 'pdpPromoboxOpen' : ''}`)}>
                    <div className={cx('pdp-promoheader', 'hiddenSm')}>
                        <button
                            type='button'
                            onClick={() =>
                                this.pdpPromoToggle(promotions.conditionalGroup.length || !_isEmpty(promotions.note))
                            }
                        >
                            <div className={cx('pdp-badgeIcon')}>
                                <Icon width='24px' height='24px' viewBox='0 0 24 24' name='promo-badge' />
                            </div>
                            <h5>
                                {localization[PDP_L10N.PROMOTION]}: {promotions.description}
                                <p className={cx('pdp-headdate')}>
                                    {localization[PDP_L10N.VALID_FROM]}: {promotions.startDate}{' '}
                                    {localization[PDP_L10N.VALID_UNTIL]}: {promotions.endDate}
                                </p>
                            </h5>
                            {!_isEmpty(promotions.note) && (
                                <div className={cx('notes', `${this.state.addClass ? 'notesOpen' : 'notesClose'}`)}>
                                    <h5>
                                        {commonLocalization[GENERIC_L10N.DETAILS]}:{' '}
                                        <span className={cx('notesDetails')}>{promotions.note}</span>
                                    </h5>
                                </div>
                            )}
                            {promotions && (promotions.conditionalGroup.length > 0 || !_isEmpty(promotions.note)) && (
                                <div className={cx('pdp-promoArrow')}>
                                    <Icon iconType='svg' viewBox='0 0 20 24' name='down' />
                                </div>
                            )}
                        </button>
                    </div>
                    <div className={cx('mpdpPromobtn', 'hiddenLg', 'hiddenMd', 'hiddenXl')}>
                        <button type='button' onClick={() => this.pdpPromoToggle(true)}>
                            {localization[PDP_L10N.PROMOTIONS]}{' '}
                            <Icon
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                name='promo-badge'
                                className={cx('promoBadgeIcon')}
                            />
                        </button>
                    </div>
                    <div className={cx('pdp-promocontent')}>
                        <div className={cx('hiddenLg', 'hiddenMd', 'hiddenXl')}>
                            <h5>
                                {localization[PDP_L10N.PROMOTION]}: {promotions.description}
                                <p>
                                    {localization[PDP_L10N.VALID_FROM]}: {promotions.startDate}{' '}
                                    {localization[PDP_L10N.VALID_UNTIL]}: {promotions.endDate}
                                </p>
                            </h5>
                        </div>
                        {!_isEmpty(promotions.note) && (
                            <div className={cx('notes', 'mobileviewNote')}>
                                <h5>
                                    {commonLocalization[GENERIC_L10N.DETAILS]}:{' '}
                                    <span className={cx('notesDetails')}>{promotions.note}</span>
                                </h5>
                            </div>
                        )}
                        {promotions &&
                            promotions.conditionalGroup.map((group, index) => (
                                <>
                                    <h5>
                                        {localization[PDP_L10N.GROUP]} {index + 1} : {group.value.description}
                                    </h5>
                                    {group.value.productInfos.map(info => {
                                        return <p>{info.name}</p>;
                                    })}
                                </>
                            ))}
                        {promotions && promotions.freeGroup && promotions.freeGroup.productInfos && (
                            <>
                                <h5>
                                    {localization[PDP_L10N.FREE_GROUP]} : {promotions.freeGroup.description}
                                </h5>
                                {promotions.freeGroup.productInfos.map(info => {
                                    return <p>{info.name}</p>;
                                })}
                            </>
                        )}
                        {limitedQuantity}
                    </div>
                    <div className={cx('hiddenSm')}>{limitedQuantity}</div>
                </div>
            </div>
        );
    };

    renderImageBar() {
        const { commonLocalization } = this.props;
        switch (this.getShowcasedFeature()) {
            case productShowcaseFeature.inStock:
                return (
                    <div className={`${styles.imageBar} ${styles.instockImageBar}`}>
                        {commonLocalization[GENERIC_L10N.IN_STOCK_LABEL]}
                    </div>
                );
            case productShowcaseFeature.outOfStock:
                return (
                    <div className={`${styles.imageBar} ${styles.oosImageBar}`}>
                        {commonLocalization[GENERIC_L10N.OUT_STOCK_LABEL]}
                    </div>
                );
            case productShowcaseFeature.promotional:
                return (
                    <div className={`${styles.imageBar} ${styles.promotionalImageBar}`}>
                        {commonLocalization[GENERIC_L10N.PROMOTION_LABEL]}
                    </div>
                );
            default:
                return <></>;
        }
    }

    renderProductLabel() {
        const { commonLocalization } = this.props;
        switch (this.getShowcasedFeature()) {
            case productShowcaseFeature.inStock:
                return (
                    <div className={`${styles.productLabel} ${styles.instockLabel} ${cx('float-left')}`}>
                        {commonLocalization[GENERIC_L10N.IN_STOCK_LABEL]}
                    </div>
                );
            case productShowcaseFeature.outOfStock:
                return (
                    <div className={`${styles.productLabel} ${styles.oosLabel} ${cx('float-left')}`}>
                        {commonLocalization[GENERIC_L10N.OUT_STOCK_LABEL]}
                    </div>
                );
            case productShowcaseFeature.promotional:
                return (
                    <div className={`${styles.productLabel} ${styles.promotionLabel} ${cx('float-left')}`}>
                        {commonLocalization[GENERIC_L10N.PROMOTION_LABEL]}
                    </div>
                );
            default:
                return <></>;
        }
    }

    renderDepositFeeIcon() {
        const { productDetailData, assetBaseUrl } = this.props;
        if (productDetailData?.depositProduct && productDetailData?.depositProductLogoUrl) {
            return (
                <img
                    src={`${assetBaseUrl}${productDetailData?.depositProductLogoUrl}`}
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '15px',
                        width: '40px',
                        height: '40px',
                        objectFit: 'contain'
                    }}
                    alt='return_fee_logo'
                />
            );
        }
    }

    renderRecommendedReason = () => {
        const { bdaaSuggestion, productDetailData } = this.props;
        const bdaaProduct = bdaaSuggestion.find(product => product.code === productDetailData?.code);
        return (
            <div className={cx('recommendedReasonContainer')}>
                <Icon
                    className={cx('flex-icon')}
                    iconType='svg'
                    width='22px'
                    height='22px'
                    viewBox='-5 -3 20 20'
                    name='thumbs-up'
                />
                <p className={cx('recommendedReasonText')}>{bdaaProduct?.reason}</p>
            </div>
        );
    };

    render() {
        const {
            productDetailData,
            localization,
            commonLocalization,
            stockAllocation,
            miniCartItems,
            params: { productId }
        } = this.props;
        const { units, imgActive, bdaaRecommendedCheckboxInfo } = this.state;
        const productTitle = (productDetailData.name || '').toLowerCase();
        const bigImage = _get(productDetailData, `imagesObjs[${imgActive}]`, {});

        const item = miniCartItems.find(item => item.code === productId);

        const basicProductDetails = {
            productCode: productId,
            quantity: item ? item.quantity : 0,
            unitCode: units || _get(productDetailData, 'units[0].value', '')
        };
        const currencySymbol = getCurrency();
        const isReturnEmpties = _get(productDetailData, 'units[0].value', '') === 'EA';
        const isMarketingMaterials = !!isUseMarketingMaterials();

        const isRecommended =
            !!this.props.bdaaSuggestion &&
            this.props.bdaaSuggestion.findIndex(product => product.code === productDetailData?.code) !== -1;
        const bdaaProduct = this.props.bdaaSuggestion
            ? this.props.bdaaSuggestion.find(product => product.code === productDetailData?.code)
            : null;
        const recommendedText = localization[PDP_L10N.BDAA_RECOMMENDED_TEXT];
        const maxQty = stockAllocation?.products.find(
            ({ product: { codeShort } }) => codeShort === productDetailData.SKU
        )?.quantity;

        const analyticsFoundPrice = (productDetailData?.itemDetails || [])
            .slice()
            .reverse()
            .find(({ value }) => /^(0(?!\.00)|[1-9]\d{0,6})\.\d{2}$/.test(value));

        const isBdaaRecommendedChecked = Object.keys(bdaaRecommendedCheckboxInfo).find(
            key => key === productDetailData.code
        );

        const isBdaaRecommendedCheckedValue = bdaaRecommendedCheckboxInfo[isBdaaRecommendedChecked]?.checked || false;

        return (
            <Layout showDashboardHeader showOutlet>
                {!_isEmpty(productDetailData) && !_isEmpty(localization) && (
                    <div className={cx('productDetailContainer')} automation-id='at-product-details-page'>
                        <NotificationSubscription />
                        <BreadCrumbs breadcrumbValues={productDetailData.breadcrumbsObjs} />
                        <section>
                            <div className={cx('row', 'pdpBox')}>
                                <div className={cx('preview', 'col6', 'sm12', 'pdpImgbox')}>
                                    {this.renderImageBar()}
                                    <div className={cx('imgleft')}>
                                        {isRecommended && recommendedText && (
                                            <span className={cx('recommendedImg')}>{recommendedText}</span>
                                        )}
                                        <div className={cx('productImg')}>
                                            {_isEmpty(bigImage.url) ? (
                                                <img src={IMAGE_COMING_SOON} alt='product coming soon' />
                                            ) : (
                                                <Image
                                                    automationId='at-product-image'
                                                    id='bigImage'
                                                    theme={cx('logoImg')}
                                                    src={bigImage.url}
                                                    alt={bigImage.altText}
                                                />
                                            )}
                                        </div>
                                        {this.renderDepositFeeIcon()}
                                        {bdaaProduct?.quantity && bdaaProduct?.reason && this.renderRecommendedReason()}
                                    </div>
                                    {_get(productDetailData, 'imagesObjs', []).length > 0 && (
                                        <div className={cx('thumbnailImg')} automation-id='at-thumbnail-image'>
                                            {_get(productDetailData, 'imagesObjs', []).map(item => {
                                                return (
                                                    <div
                                                        className={cx(imgActive === item.galleryIndex && 'activeImg')}
                                                        key={item.galleryIndex}
                                                        onClick={() => {
                                                            this.handleThumbnails(item.galleryIndex);
                                                        }}
                                                        tabIndex={0}
                                                        aria-label='fav'
                                                        role='button'
                                                        onKeyDown={() => {
                                                            this.handleThumbnails(item.galleryIndex);
                                                        }}
                                                    >
                                                        {_isEmpty(item.url) ? (
                                                            <img src={IMAGE_COMING_SOON} alt='product coming soon' />
                                                        ) : (
                                                            <Image src={item.url} alt={item.altText} />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                                <div className={cx('pdpDetails', 'col5', 'sm12', 'pdpContbox')}>
                                    <div
                                        className={cx(
                                            'pdpDetails',
                                            'float-right',
                                            'sm12',
                                            'text-right',
                                            'marb40',
                                            'hiddenSm'
                                        )}
                                    >
                                        <div className={cx('marr24', 'float-left')}>
                                            <AddToOrderTemplate
                                                cardImg={cx('cardImg')}
                                                automationId='at-add-item-to-order-template-icon'
                                                basicProductDetails={basicProductDetails}
                                                modalTitle={commonLocalization[GENERIC_L10N.TEMP_OP_TITLE]}
                                                attBtnText={localization[TEMP_POPUP_L10N.ADD_TO_TEMP_TEXT]}
                                            />
                                        </div>
                                        <div className={cx('float-left')}>
                                            <button
                                                automation-id='at-favourite-icon'
                                                type='button'
                                                className={cx('favouriteIcon')}
                                                onClick={() => {
                                                    this.handleFavourite();
                                                }}
                                            >
                                                <span className={cx('visibleSm')}>
                                                    {localization[PDP_L10N.FAVORITE]}
                                                </span>
                                                {_get(productDetailData, 'isFavourite', false) ? (
                                                    <span className={cx('iconTheme')}>
                                                        <Icon
                                                            width='24px'
                                                            height='24px'
                                                            viewBox='0 0 24 24'
                                                            name='fav-icon-active'
                                                        />
                                                    </span>
                                                ) : (
                                                    <span className={cx('iconTheme')}>
                                                        {' '}
                                                        <Icon
                                                            iconType='svg'
                                                            width='24px'
                                                            height='24px'
                                                            viewBox='0 0 24 24'
                                                            name='fav_icon'
                                                        />
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    <h3
                                        translate='no'
                                        className={cx('productTitle', 'head1', 'clearFloat')}
                                        automation-id='at-product-name'
                                    >
                                        {productTitle}
                                    </h3>
                                    <div className={cx('productSubWrapper')}>
                                        <h5 className={cx('skuTxt', 'float-left')} automation-id='at-product-id'>{`${
                                            commonLocalization[GENERIC_L10N.SKU]
                                        } ${productDetailData.SKU || ''}`}</h5>
                                        {!isReturnEmpties && (
                                            <div className={cx('selectWrapper', 'float-left')}>
                                                <SelectBox
                                                    automation-id='at-uom-selection-dropdown'
                                                    arrowTheme={cx('arrowTheme')}
                                                    theme={cx('selectQty')}
                                                    id='unitsOfMeasure'
                                                    onChange={this.onUnitsChangeHandler}
                                                    defaultValue={basicProductDetails.unitCode}
                                                    options={productDetailData.units}
                                                    disabled={isMarketingMaterials}
                                                />
                                            </div>
                                        )}

                                        {this.renderProductLabel()}

                                        {_get(productDetailData, 'price', '').length > 0 && (
                                            <p className={cx('price', 'float-right')}>{`${currencySymbol.symbol} ${_get(
                                                productDetailData.price[0],
                                                'value',
                                                ''
                                            )}`}</p>
                                        )}
                                    </div>
                                    <p
                                        className={cx('productDescription', 'col12', 'pad0')}
                                        automation-id='at-product-details'
                                    >
                                        {productDetailData.description}
                                    </p>
                                    <div className={cx('addToCartContainer', 'col12', 'pad0')}>
                                        {bdaaProduct?.quantity && bdaaProduct?.reason && (
                                            <div className={cx('suggestedQtyContainer')}>
                                                <Checkbox
                                                    automation-id='at-checkbox'
                                                    name='suggested-quanity-checkbox'
                                                    checked={isBdaaRecommendedCheckedValue}
                                                    themecheckboxCheckmark={cx(
                                                        isBdaaRecommendedCheckedValue
                                                            ? 'suggestedQtyCheckboxChecked'
                                                            : 'suggestedQtyCheckbox'
                                                    )}
                                                    onChange={() => {
                                                        const { bdaaRecommendedCheckboxInfo } = this.state;
                                                        const existingValue = bdaaRecommendedCheckboxInfo;
                                                        if (Object.keys(existingValue).length === 0) {
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: true
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: true
                                                                    }
                                                                }
                                                            });
                                                        } else if (
                                                            Object.keys(existingValue).find(key => {
                                                                return key === productDetailData.code;
                                                            })
                                                        ) {
                                                            // If the product code is found
                                                            // Access the existing product info using the product code
                                                            const bdaaProductInfo =
                                                                existingValue[productDetailData.code];

                                                            // Toggle the checked status in local storage and state
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: !bdaaProductInfo.checked, // Toggle the checked status,
                                                                    hasBeenAddedToCart: false
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: !bdaaProductInfo.checked, // Toggle the checked status
                                                                        hasBeenAddedToCart: false
                                                                    }
                                                                }
                                                            });
                                                        } else {
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: true
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: true
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }}
                                                />
                                                <div
                                                    tabIndex='-1'
                                                    role='button'
                                                    className={cx('suggestedQtySubContainer')}
                                                    onClick={() => {
                                                        const { bdaaRecommendedCheckboxInfo } = this.state;
                                                        const existingValue = bdaaRecommendedCheckboxInfo;
                                                        if (Object.keys(existingValue).length === 0) {
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: true
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: true
                                                                    }
                                                                }
                                                            });
                                                        } else if (
                                                            Object.keys(existingValue).find(key => {
                                                                return key === productDetailData.code;
                                                            })
                                                        ) {
                                                            // If the product code is found
                                                            // Access the existing product info using the product code
                                                            const bdaaProductInfo =
                                                                existingValue[productDetailData.code];

                                                            // Toggle the checked status in local storage and state
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: !bdaaProductInfo.checked, // Toggle the checked status,
                                                                    hasBeenAddedToCart: false
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: !bdaaProductInfo.checked, // Toggle the checked status
                                                                        hasBeenAddedToCart: false
                                                                    }
                                                                }
                                                            });
                                                        } else {
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: true
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: true
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }}
                                                    onKeyDown={() => {
                                                        const { bdaaRecommendedCheckboxInfo } = this.state;
                                                        const existingValue = bdaaRecommendedCheckboxInfo;
                                                        if (Object.keys(existingValue).length === 0) {
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: true
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: true
                                                                    }
                                                                }
                                                            });
                                                        } else if (
                                                            Object.keys(existingValue).find(key => {
                                                                return key === productDetailData.code;
                                                            })
                                                        ) {
                                                            // If the product code is found
                                                            // Access the existing product info using the product code
                                                            const bdaaProductInfo =
                                                                existingValue[productDetailData.code];

                                                            // Toggle the checked status in local storage and state
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: !bdaaProductInfo.checked, // Toggle the checked status,
                                                                    hasBeenAddedToCart: false
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: !bdaaProductInfo.checked, // Toggle the checked status
                                                                        hasBeenAddedToCart: false
                                                                    }
                                                                }
                                                            });
                                                        } else {
                                                            setBdaaRecommendedCheckboxInfo({
                                                                [productDetailData.code]: {
                                                                    checked: true
                                                                }
                                                            });
                                                            // Directly update state with the product's code as key, keeping the object structure
                                                            this.setState({
                                                                bdaaRecommendedCheckboxInfo: {
                                                                    [productDetailData.code]: {
                                                                        checked: true
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <Icon
                                                        className={cx('flex-icon')}
                                                        iconType='svg'
                                                        width='25px'
                                                        height='25px'
                                                        viewBox='-5 -3 20 20'
                                                        name='thumbs-up'
                                                    />
                                                    <p className={cx('addSuggestedQtyText')}>
                                                        {commonLocalization[GENERIC_L10N.ADD_QTY]}:{' '}
                                                        {bdaaProduct?.quantity}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <div className={cx('col6', 'md7', 'sm12', 'pad0', 'pos-rel')}>
                                            <div>
                                                <AddToCart
                                                    maxValue={maxQty}
                                                    buttonType='Primary'
                                                    automationId='at-add-to-card-button'
                                                    iconName='addCart-Icon'
                                                    autoHeight
                                                    customAddtoCartStyle={cx('addtoCart')}
                                                    buttonValue={commonLocalization[GENERIC_L10N.ADD_TO_CART]}
                                                    stockFlag={productDetailData.stockFlag}
                                                    stockNotified={productDetailData.stockNotified}
                                                    productInfo={[
                                                        {
                                                            code: productDetailData.code,
                                                            quantityType: basicProductDetails.unitCode,
                                                            quantity: basicProductDetails.quantity,
                                                            conversionFactors: productDetailData.conversionFactors
                                                        }
                                                    ]}
                                                    analyticsProductInfo={[
                                                        {
                                                            name: productDetailData.name,
                                                            id: productDetailData.SKU,
                                                            uom: units || productDetailData.units[0].value,
                                                            quantity: basicProductDetails.quantity,
                                                            price: analyticsFoundPrice
                                                                ? getProductPrice(analyticsFoundPrice.value)
                                                                : 0,
                                                            brand: productDetailData.dataLayer.brand,
                                                            category: productDetailData.dataLayer.category
                                                        }
                                                    ]}
                                                    addToCartMessage={localization[GENERIC_L10N.ADD_TO_CART_MESSAGE]}
                                                    addToCartNotifyButton={
                                                        localization[GENERIC_L10N.STOCK_NOTIFICATION.NOTIFY_ME]
                                                    }
                                                    addToCartNotifiedButton={
                                                        localization[GENERIC_L10N.STOCK_NOTIFICATION.GET_NOTIFIED]
                                                    }
                                                    openStockNotificationModal={() =>
                                                        this.props.actions.openSubscriptionPopup(productDetailData.code)
                                                    }
                                                    blockAddToCartEnabled={productDetailData.blockAddToCartEnabled}
                                                    bdaaRecommendedCheckboxInfo={this.state.bdaaRecommendedCheckboxInfo}
                                                    baseUnit={productDetailData.units[0].value}
                                                />
                                            </div>
                                        </div>
                                        {basicProductDetails.quantity === maxQty && (
                                            <div className={cx('maxQtyWarn')}>
                                                <Icon
                                                    iconType='svg'
                                                    width='20px'
                                                    height='20px'
                                                    viewBox='0 0 23 23'
                                                    name='info-red'
                                                />{' '}
                                                {commonLocalization[PLP_L10N.STOCK_ALLOCATION_LIMIT_MESSAGE]}
                                            </div>
                                        )}

                                        {!isReturnEmpties && !isMarketingMaterials && (
                                            <div className={cx('marr24', 'hiddenMd', 'hiddenLg', 'hiddenXl')}>
                                                <AddToOrderTemplate
                                                    cardImg={cx('cardImg')}
                                                    automationId='at-add-item-to-order-template-icon'
                                                    basicProductDetails={basicProductDetails}
                                                    modalTitle={commonLocalization[GENERIC_L10N.TEMP_OP_TITLE]}
                                                    attBtnText={localization[TEMP_POPUP_L10N.ADD_TO_TEMP_TEXT]}
                                                    pdpBtn
                                                />
                                            </div>
                                        )}
                                        {!isReturnEmpties && !isMarketingMaterials && (
                                            <div className={cx('hiddenMd', 'hiddenLg', 'hiddenXl')}>
                                                <button
                                                    automation-id='at-favourite-icon'
                                                    type='button'
                                                    className={cx('favouriteIcon')}
                                                    onClick={() => {
                                                        this.handleFavourite();
                                                    }}
                                                >
                                                    <span>{localization[PDP_L10N.FAVORITE]}</span>
                                                    {_get(productDetailData, 'isFavourite', false) ? (
                                                        <span className={cx('iconTheme', 'faviIcon')}>
                                                            <Icon
                                                                width='24px'
                                                                height='24px'
                                                                viewBox='0 0 24 24'
                                                                name='fav-icon-active'
                                                            />
                                                        </span>
                                                    ) : (
                                                        <span className={cx('iconTheme', 'faviIcon')}>
                                                            {' '}
                                                            <Icon
                                                                iconType='svg'
                                                                width='24px'
                                                                height='24px'
                                                                viewBox='0 0 24 24'
                                                                name='fav_icon'
                                                            />
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        )}
                                        {isRecommended && (
                                            <div className={cx('bdaaProductText')}>
                                                <div className={cx('circle')}>i</div>
                                                <div>
                                                    <span>{localization.recommendedProd1} </span>
                                                    <b>{localization.recommendedProdBold} </b>
                                                    <span>{localization.recommendedProd2} </span>
                                                    <b>
                                                        <Link
                                                            to={URL_PATHS.PLP_ORDER_RECOMMENDED}
                                                            className={cx('bdaaLink')}
                                                        >
                                                            {localization.recommendedProdLink}
                                                        </Link>
                                                    </b>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {_get(productDetailData, 'isPromoProduct', false) && this.renderPromotions()}

                                    <div className={cx('tab')}>
                                        <Accordion
                                            title={localization[PDP_L10N.ITEM_DETAILS]}
                                            customTitle={cx('itemTitle')}
                                            setActive
                                        >
                                            <table automation-id='at-product-details' className={cx('itemDetails')}>
                                                <tbody>
                                                    {_get(productDetailData, 'itemDetails', []).map(item => {
                                                        const updatedValue =
                                                            (item?.code === 'csGrossValue' ||
                                                                item?.code === 'stGrossValue') &&
                                                            this.enabledEURConversion
                                                                ? `${item.value} ${currencySymbol.symbol} ${convertBGNToEUR(item.value, false)}`
                                                                : item.value;
                                                        return (
                                                            <tr key={item.label}>
                                                                <td>{item.label}</td>
                                                                <td>{updatedValue}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        assetBaseUrl: _get(state, 'context.environment.assetBaseUrl'),
        productDetailData: state.productDetailData,
        miniCartItems: _get(state, 'cartDetails.miniCartItems', []),
        cartDeliveryDate: _get(state, 'cartDetails.deliveryDate'),
        localization: _get(state, 'pageContent.localization'),
        stockAllocation: _get(state, 'pageContent.stockAllocation'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        bdaaSuggestion: _get(state, 'products.bdaaSuggestion')
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getProductDetails,
            removeFavouriteEvent,
            addToFavouriteEvent,
            resetProductDetails,
            getPageContent,
            setPageTitlePrefix,
            getBdaaSuggestion,
            openSubscriptionPopup
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
