import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { getLocalization } from '../common/UserManager/UserManager';
import { LOGIN_INFO } from '../common/Constants';

// eslint-disable-next-line import/prefer-default-export
export const filterCountryData = list => {
    const countryList = [];
    const lanaguageList = {};
    let selectedCountry = {};
    let selectedLanguage = {};
    const { LANG, SITE_ID } = LOGIN_INFO;
    const localization = getLocalization();

    for (let i = 0; i < list.length; i += 1) {
        if (!list[i].siteId || !list[i].languages) {
            // eslint-disable-next-line no-continue
            continue;
        }
        const country = list[i];
        const countryObj = {
            id: country.siteId,
            value: country.siteId,
            label: country.title,
            redirectionUrl: country?.redirectionUrl || null
        };
        if (localization[SITE_ID] === country.siteId) {
            selectedCountry = {
                id: country.siteId,
                value: country.siteId,
                label: country.title,
                redirectionUrl: country?.redirectionUrl || null
            };
        }
        countryList.push(countryObj);
        lanaguageList[country.siteId] = [];

        const { languages } = country;
        for (let j = 0; j < languages.length; j += 1) {
            const language = languages[j];
            const langName = language.name || language.nativeName;

            if (langName) {
                const langObj = {
                    id: language.isocode,
                    value: language.isocode,
                    oneTrustIsoCode: language.oneTrustIsoCode,
                    label: langName,
                    default: language.isDefault
                };
                if (localization[LANG] === language.isocode) {
                    selectedLanguage = langObj;
                }
                lanaguageList[country.siteId].push(langObj);
            }
        }
    }
    if (_isEmpty(selectedCountry)) {
        [selectedCountry] = countryList;
    }
    if (_isEmpty(selectedLanguage)) {
        const selectedLangList = lanaguageList[selectedCountry.value];
        selectedLanguage = _find(selectedLangList, ['default', true]) || selectedLangList[0];
    }

    return {
        countryList,
        selectedCountry,
        lanaguageList,
        selectedLanguage,
    };
};
