import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { scroller } from 'react-scroll';
import Cookies from 'universal-cookie';
import classNames from 'classnames/bind';
import PreLoginHeader from './PreLoginHeader';
import Banner from './Banner';
import Benefits from './Benefits';
import Workflow from './Workflow';
import WhyCCHBC from './WhyCCHBC';
import OurCommitment from './OurCommitment';
import SelectLanguageModal from './SelectLanguageModal';
import Footer from '../../../components/site/Footer/FooterLogin';
import Button from '../../../components/core/Button/Button';
import Modal from '../../../components/core/Modal/Modal';
import { LOGIN_INFO, LOGIN_UID, PAGE_ID, PRE_LOGIN_UID, URL_PATHS } from '../../../common/Constants';
import { isLocalizationSelected, getLocalization, getSiteId } from '../../../common/UserManager/UserManager';
import { findComponent, redirectTo } from '../../../common/Utils/Utils';
import * as pageActions from '../../../actions/PageContentAction';
import * as styles from './PreLoginPage.css';
import ReadyToOrder from './ReadyToOrder';
import FAQ from './FAQ';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const filterCmsComponents = (cmsComponents, componentUid) => {
    return cmsComponents.filter(cmsComponent => cmsComponent.uid.startsWith(componentUid));
};

const PreLoginPage = () => {
    const {
        pageContent: { pageTitle }
    } = useSelector(state => state);
    const redirectCountries = useSelector(state => state.context.preferences.redirectCountries);
    const cookies = new Cookies();
    const siteIdCookie = cookies.get(LOGIN_INFO.SITE_ID);

    const country = getLocalization()[LOGIN_INFO.COUNTRY] || '';

    const dispatch = useDispatch();
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [isShowCustomSignupPopup, setIsShowCustomSignupPopup] = useState(false);
    const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);
    const cmsComponents = useSelector(state => state.pageContent.components) || [];

    const logoComponents = filterCmsComponents(cmsComponents, PRE_LOGIN_UID.LOGO_COMPONENTS.UID);
    const mobileMenuLogoComponent = filterCmsComponents(cmsComponents, PRE_LOGIN_UID.LOGO_COMPONENTS.MOBILE_UID);
    const backgroundComponents = filterCmsComponents(
        cmsComponents,
        PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.UID_CONTAINS
    );
    const benefitsComponents = filterCmsComponents(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.UID_CONTAINS);
    const workflowComponents = filterCmsComponents(cmsComponents, PRE_LOGIN_UID.HOWITWORKS_COMPONENTS.UID_CONTAINS);
    const whyCCHBCComponents = filterCmsComponents(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.UID_CONTAINS);
    const ourcommitmentComponents = filterCmsComponents(
        cmsComponents,
        PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.UID_CONTAINS
    );

    const footerComp = findComponent(cmsComponents, PRE_LOGIN_UID.FOOTER_COMPONENTS.UID);
    let footerComponent;
    if (footerComp) {
        footerComponent = {
            ...footerComp,
            [LOGIN_UID.IMPRINT]: footerComp?.childComponents?.find(components => components.uid === LOGIN_UID.IMPRINT),
            [LOGIN_UID.OUR_COMPANY]: footerComp?.childComponents?.find(
                components => components.uid === LOGIN_UID.OUR_COMPANY
            ),
            [LOGIN_UID.CONTACT_US]: footerComp?.childComponents?.find(
                components => components.uid === LOGIN_UID.CONTACT_US
            ),
            [LOGIN_UID.TERMS]: footerComp?.childComponents?.find(components => components.uid === LOGIN_UID.TERMS),
            [LOGIN_UID.PRIVACY]: footerComp?.childComponents?.find(components => components.uid === LOGIN_UID.PRIVACY),
            analyticsEvents: {
                onImprintClick: () => {
                    buttonClickEvent(
                        buttonClickEventConfigs.preLogin.actions.preLoginFooter,
                        buttonClickEventConfigs.preLogin.labels.preLoginFooterImprint,
                        buttonClickEventConfigs.preLogin.categories.preLogin
                    );
                },
                onOurCompanyClick: () => {
                    buttonClickEvent(
                        buttonClickEventConfigs.preLogin.actions.preLoginFooter,
                        buttonClickEventConfigs.preLogin.labels.preLoginFooterOurCompany,
                        buttonClickEventConfigs.preLogin.categories.preLogin
                    );
                },
                onPrivacyClick: () => {
                    buttonClickEvent(
                        buttonClickEventConfigs.preLogin.actions.preLoginFooter,
                        buttonClickEventConfigs.preLogin.labels.preLoginFooterPrivacyCookiePolicy,
                        buttonClickEventConfigs.preLogin.categories.preLogin
                    );
                }
            }
        };
    }

    const headerComponents = cmsComponents.filter(x => x.uid.includes('Header'));
    const benefitsLink = findComponent(headerComponents, PRE_LOGIN_UID.HEADER_LINKS.BENEFITS_UID);
    const workflowLink = findComponent(headerComponents, PRE_LOGIN_UID.HEADER_LINKS.WORKFLOW_UID);
    const aboutUsLink = findComponent(headerComponents, PRE_LOGIN_UID.HEADER_LINKS.ABOUTUS_UID);
    const whyCCHBCLink = findComponent(headerComponents, PRE_LOGIN_UID.HEADER_LINKS.WHYCCHBC_UID);
    const ourCommitmentLink = findComponent(headerComponents, PRE_LOGIN_UID.HEADER_LINKS.OURCOMMITMENTS_UID);
    const signupLink = findComponent(cmsComponents, PRE_LOGIN_UID.HEADER_LINKS.SIGNUP_UID);
    const signupCustomLink = findComponent(cmsComponents, PRE_LOGIN_UID.HEADER_LINKS.SIGNUP_CUSTOM_LINK);
    const yesBtnHeader = findComponent(cmsComponents, PRE_LOGIN_UID.HEADER_LINKS.YES_BTN);
    const noBtnHeader = findComponent(cmsComponents, PRE_LOGIN_UID.HEADER_LINKS.NO_BTN);
    const customModalText = findComponent(cmsComponents, PRE_LOGIN_UID.HEADER_LINKS.CUSTOM_MODAL_TEXT);
    const loginLink = findComponent(cmsComponents, PRE_LOGIN_UID.HEADER_LINKS.LOGIN_UID);
    const headerLinks = { benefitsLink, workflowLink, aboutUsLink, whyCCHBCLink, ourCommitmentLink };
    const callUsBtn = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.CALL_US_BTN_UID);
    const readMoreBtn = findComponent(cmsComponents, PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.READ_MORE_BTN_UID);
    const callUsCountries = ['ni', 'roi', 'austria'];

    const localization = getLocalization();
    document.body.classList.add(localization[LOGIN_INFO.SITE_ID]);
    document.body.classList.add(localization[LOGIN_INFO.LANG]);

    useEffect(() => {
        const pageId = PAGE_ID.PRE_LOGIN;
        const params = {
            siteId: localization?.siteId || 'swiss',
            lang: localization?.lang || 'en'
        };

        dispatch(pageActions.getPageContent({ pageId, params }));
    }, [localization.siteId, localization.lang]);

    // Redirect to CP2 prelogin if cookies contain siteId which is part of the CP2 list in settings.json
    useEffect(() => {
        if (siteIdCookie && redirectCountries[siteIdCookie]) {
            window.location.href = `${redirectCountries[siteIdCookie].redirectUrl}/prelogin`;
        }
    }, [siteIdCookie, redirectCountries]);
    
    const scrollToSection = id => {
        const options = {
            duration: 600,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -50
        };

        scroller.scrollTo(id, options);
    };

    const openModal = e => {
        e.preventDefault();
        setIsShowPopup(true);
    };

    const onCloseModal = () => {
        setIsShowPopup(false);
    };

    const handlePopup = e => {
        e.preventDefault();
        openModal(e);
    };

    const handleCustomSignUpPopup = e => {
        e.preventDefault();
        setIsShowCustomSignupPopup(true);
    };

    const renderModal = () => {
        const callUsComp = findComponent(cmsComponents, PRE_LOGIN_UID.FOOTER_COMPONENTS.CALL_US);
        const phoneComp = findComponent(cmsComponents, PRE_LOGIN_UID.FOOTER_COMPONENTS.PHONE);
        const cancelComp = findComponent(cmsComponents, PRE_LOGIN_UID.FOOTER_COMPONENTS.CANCEL_BTN);
        const callUsText = callUsComp?.content;
        const phoneTxt = phoneComp?.content;
        const cancelBtn = cancelComp?.content;

        return (
            <div className={cx('contactUsModal')}>
                <Modal
                    showModal={isShowPopup}
                    modalTheme={cx('userThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('themeModalBlock')}
                    defaultCloseBtn={false}
                >
                    <div className={cx('clearContent')}>
                        <p>
                            {callUsText}
                            <a
                                href={`tel:${phoneTxt}`}
                                className={cx('phoneTxt')}
                                onClick={() =>
                                    buttonClickEvent(
                                        buttonClickEventConfigs.preLogin.actions.preLoginCallUsPopUp,
                                        buttonClickEventConfigs.preLogin.labels.preLoginCallUsPopUpCallUs,
                                        buttonClickEventConfigs.preLogin.categories.preLogin
                                    )
                                }
                            >
                                {phoneTxt}
                            </a>
                        </p>

                        <div className={cx('bottomBtns')}>
                            <Button
                                type='button'
                                size={cx('Sm')}
                                className={cx('userBtns', 'cancelBtn')}
                                onClick={() => {
                                    onCloseModal();
                                    buttonClickEvent(
                                        buttonClickEventConfigs.preLogin.actions.preLoginCallUsPopUp,
                                        buttonClickEventConfigs.preLogin.labels.preLoginCallUsPopUpCancel,
                                        buttonClickEventConfigs.preLogin.categories.preLogin
                                    );
                                }}
                            >
                                {cancelBtn}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };

    const renderCustomSignupModal = () => {
        return (
            <div className={cx('contactUsModal')}>
                <Modal
                    showModal={isShowCustomSignupPopup}
                    modalTheme={cx('userThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('close-custom-modal')}
                    themeModalBlock={cx('themeModalBlock')}
                    defaultCloseBtn
                    onClose={() => {
                        setIsShowCustomSignupPopup(false);
                        buttonClickEvent(
                            buttonClickEventConfigs.preLogin.actions.preLoginSignUpPopUp,
                            buttonClickEventConfigs.preLogin.labels.preLoginSignUpPopUpCancel,
                            buttonClickEventConfigs.preLogin.categories.preLogin
                        );
                    }}
                >
                    <div className={cx('clearContent')}>
                        <p>{customModalText?.content.split('<a>')[0]}</p>
                    </div>
                    <div className={cx('yes-no-button-row')}>
                        <Button
                            type='button'
                            className={cx('no-btn')}
                            onClick={() => {
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginSignUpPopUp,
                                    buttonClickEventConfigs.preLogin.labels.preLoginSignUpPopUpNo,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                                window.open(signupCustomLink?.url, '_blank');
                                setIsShowCustomSignupPopup(false);
                            }}
                        >
                            {noBtnHeader?.content}
                        </Button>
                        <Button
                            type='button'
                            buttonType='Primary'
                            className={cx('yes-btn')}
                            onClick={e => {
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginSignUpPopUp,
                                    buttonClickEventConfigs.preLogin.labels.preLoginSignUpPopUpYes,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                                if (callUsCountries.includes(localization?.siteId)) {
                                    openModal(e);
                                } else {
                                    redirectTo(URL_PATHS.REGISTER);
                                }
                                setIsShowCustomSignupPopup(false);
                            }}
                        >
                            {yesBtnHeader?.content}
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>{`${pageTitle} ${country}`}</title>
                {/* if needed description or other meta tags can be added */}
            </Helmet>
            {(!isLocalizationSelected() || isOpenLanguageModal) && <SelectLanguageModal />}
            <div className={cx('prelogin-layout', !isLocalizationSelected() ? 'disable-interactions' : '')}>
                <PreLoginHeader
                    headerLinks={headerLinks}
                    signupLink={signupLink}
                    loginLink={loginLink}
                    logoComponents={logoComponents}
                    mobileMenuLogoComponent={mobileMenuLogoComponent}
                    scrollToSection={scrollToSection}
                    openlanguageModal={() => setIsOpenLanguageModal(true)}
                    handleCustomSignUpPopup={handleCustomSignUpPopup}
                />
                <Banner cmsComponents={backgroundComponents} handleCustomSignUpPopup={handleCustomSignUpPopup} signupLink={signupLink} handlePopup={handlePopup} />
                <Benefits className={benefitsLink?.content} cmsComponents={benefitsComponents} />
                <Workflow className={workflowLink?.content} cmsComponents={workflowComponents} />
                <WhyCCHBC
                    className={whyCCHBCLink?.content}
                    cmsComponents={whyCCHBCComponents}
                    callUsBtn={callUsBtn}
                    contactUsComponent={footerComp ? footerComponent[LOGIN_UID.CONTACT_US] : null}
                    handlePopup={handlePopup}
                />
                <OurCommitment
                    className={ourCommitmentLink?.content}
                    cmsComponents={ourcommitmentComponents}
                    readMoreBtn={readMoreBtn}
                />
                <FAQ />
                <ReadyToOrder
                    className={ourCommitmentLink?.content}
                    cmsComponents={ourcommitmentComponents}
                    loginLink={loginLink}
                    signupLink={signupLink}
                    handlePopup={handlePopup}
                    callUsBtn={callUsBtn}
                />
                <Footer isLoginFooter footerComponent={footerComponent} handlePopup={handlePopup} />
                {isShowPopup && renderModal()}
                {isShowCustomSignupPopup && renderCustomSignupModal()}
            </div>
        </>
    );
};

export default PreLoginPage;
