import {
    SET_SUCCESS_NOTIFICATION,
    SET_BLUE_NOTIFICATION,
    SET_WARN_NOTIFICATION,
    SET_ERROR_NOTIFICATION,
    CLEAR_NOTIFICATION,
    CLEAR_ALL_NOTIFICATION,
    SET_MILTIPLE,
    PUSH_NOTIFICATIONS,
    SHOW_ORDER_NOTIFICATIONS,
    CLEAR_SPECIFIC_NOTIFICATION
} from '../actionTypes/NotificationActionTypes';


export const pushNotifications = data => ({
    type: PUSH_NOTIFICATIONS,
    data
})

export const showOrderNotifications = data => ({
    type: SHOW_ORDER_NOTIFICATIONS,

    data
})

export const showMultipleNotifications = data => ({
    type: SET_MILTIPLE,
    data
})

export const notifySuccess = data => ({
    type: SET_SUCCESS_NOTIFICATION,
    data
});

export const notifyBlueMessage = data => ({
    type: SET_BLUE_NOTIFICATION,
    data
});

export const notifyWarning = data => ({
    type: SET_WARN_NOTIFICATION,
    data
});

export const notifyError = data => {
    return {
        type: SET_ERROR_NOTIFICATION,
        data
    };
};

export const clearNotification = (index, isCutOff) => ({
    type: CLEAR_NOTIFICATION,
    index,
    isCutOff
});

export const clearSpecificNotification = (msg) => ({
    type: CLEAR_SPECIFIC_NOTIFICATION,
    data: msg
});

export const clearAllNotification = () => ({
    type: CLEAR_ALL_NOTIFICATION
});
